.navSideBar {
    margin: 0px;
    padding-bottom: 20px;
    font-size: calc(0.3rem + 0.5vw);
    font-weight: 500;
    display: flex;
    margin-right: auto;
    margin-top: 5vh;
    width:100%;
    border-top: 2px solid goldenrod;
    height: 75vh;
    overflow-y: scroll;
  }

  .navSideBar li{
    height: 3vh;
    width: 100%;
  }
  