#AllStaffPage_401
{
    max-height: 75vh;
    min-height: 75vh;
    overflow-y: scroll;
    border: 2px solid goldenrod;
}

#PendingStaffPage
{
    max-height: 80vh;
    min-height: 80vh;
    overflow-y: scroll;
    border: 2px solid goldenrod;
}

#AllStaff_Table_Th
{
    font-size: calc(0.3rem + 0.3vw);
}

#AllStaff_Table_Td
{
    width: 5vw;
    font-size: calc(0.3rem + 0.3vw);
}

#AllStaff_Table_Td_Loc
{
    width: 6vw;
}
#AllStaff_Table_Td_Status
{
    width: 2vw;
}


#AllStaff_Table_Td_title
{
    font-size: calc(0.3rem + 0.3vw);
    width: 5vh;
}


#AllStaff_Table_Td .btn
{
    font-size: calc(0.3rem + 0.4vw);
    height: fit-content;
    width: 7vw;
}

#AllStaff_Table_Td_Loc .btn
{
    font-size: calc(0.3rem + 0.4vw);
    height: fit-content;
    width: 100%;
}

#AllStaffPage_401_Table #inputParam_AllLocation401
{
    font-size: calc(0.3rem + 0.3vw);
}

#btnSaveBottom
{
    margin-right: 1.5vw;
    margin-bottom: 1vh;
}
