.App {
  text-align: center;
}

.content{
margin-left: 0px;
margin-top: 100px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
/* 
@media screen and (max-width: 600px) {
  .navbarSupportedContent li:not(:first-child) {display: none;}
  .navbarSupportedContent li.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .navbarSupportedContent.responsive {position: relative;}
  .navbarSupportedContent.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .navbarSupportedContent.responsive li {
    float: none;
    display: block;
    text-align: left;
  }
}

.navbarSupportedContent {
  overflow: hidden;
  background-color: #333;
}

.navbarSupportedContent li {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.navbarSupportedContent li:hover {
  background-color: #ddd;
  color: black;
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
