body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.employees-container {
  overflow: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: montserrat, sans-serif;
}

input, button {
  appearance: none;
  background: none;
  border: none;
  outline: none;
}

.LogInPage {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #53565A;
}

.addLocation {
  margin-top: 30px;
  margin-left: 30px;
  height: 80vh;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:burlywood;
}


form {
  display: block;
  position: relative;

}

form:after {
  content: '';
  display: block;
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  z-index: 1;
  background-image: linear-gradient(to bottom right, #ffce00, #fe4880);
}

form .form-inner {
  position: relative;
  display: block;
  background-color: #FFF;
  padding: 30px;
  z-index: 2;
}

form .form-inner h2 h6 {
  color: #888;
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 30px;
}
form .form-inner .form-group {
  display: block;
  width: 300px;
  margin-bottom: 15px;
}

.form-inner .form-group label {
  display: block;
  color: #666;
  font-size: 12px;
  margin-bottom: 5px;
  transition: 0.4px;
}

.form-inner .form-group:focus-within label {
  color: #fe4880;
}

form .form-inner .form-group input {
  display: block;
  width: 100%;
  padding: 10px 15px;
  background-color:lightgray;
  border-radius: 8px;
  transition: 0.4s;
}

form .form-inner .form-group input:focus {
  box-shadow: 0px 0px 3px rgba(0,0,0,0.2);
}

form .form-inner input[type="submit"], .welcome button {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 8px;
  background-image: linear-gradient(to right, #ffce00 50%, #ffce00 50%);
  background-size: 200%;
  background-position: 0%;
  transition: 0.4s;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
}

form .form-inner input[type="submit"]:hover, .welcome button:hover {
  background-position: 100% 0%;
}

.welcome {
  width: 100%;
  max-width: 480px;
  background-color: #fff;
  padding: 15px 30px;
}

.form-control
{
  width: 80%;
  height: 40px;
  padding-top: 50px;
}


.welcome h2 {
  color: #888;
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 30px;
}

.welcome h2 span{
  color: #FE4880;
  font-weight: 700;
}

.mainContainer 
{
  width: 100%;
  padding-top: 1px;
  padding-left: 10px;
  
}

#Container_Body
{
    width: 98%;
    margin-left: auto;
    margin-right: auto;
}

#Container_Body_Row
{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.staffPageCard {
  width: 100%;
}

.teamCard {
  width: 250px;
  margin: 20px 0px 0px 20px;
  overflow:hidden;
}
.imgTeamCard {
margin-top: 0px;
}

.teamCard img{
  border-radius: 50%;
  background: white;
}
/*.d-flex .btn{
  position: sticky !important;
  margin-right: 5px;
  margin-bottom: 20px;
}*/

.navbar.transparent {
  background-color: #212121;
  opacity: .85;
}

.btn-locdropLoc
{
width: 100%;
  display: block;
  border-radius: 8px;
  height: fit-content;
  /*height: 63px;*/
}

.dropdown-menu{
    position: absolute;
      display: none;
      min-width: 10rem;
      max-height: 300px;
      overflow-y: auto;
      padding: 0.5rem 0;
      margin: 0;
      font-size: 1rem;
      color: #212529;
      text-align: left;
      list-style: none;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid rgba(0,0,0,.15);
      border-radius: 0.25rem;
  }

  .pendinngCountDiv{
    margin-left: 30px;
    font-size: larger;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    color:brown;
    margin-top: 110px;
    
  }

  .allLocationBtnFloat #allLocationBtnFloat
  {
    width: 200px;
  }