#inputParam_AllLocation401
{
    border: none;
    width: 90%;
    padding: 0px;
    padding: 1px;
    font-size: calc(0.3rem + 0.5vw);
}

#inputParam_AllLocation401:hover
{
    border-radius: 5px;
    -webkit-box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
}

.allLocationBtnFloat .btn
{
margin-left: 0.5vw;
}

.allLocationBtnFloat #AllLocation_BtnRight
{
margin-right: 1vw;
}
